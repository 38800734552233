import React, { useState } from "react";
import { uploadFile } from "../api";
import { InputGroup, Alert, Button, Form, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FileUpload = ({ onFileUploaded, token }) => {
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState("");
  const [info, setInfo] = useState("");
  const [iban, setIban] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [amount, setAmount] = useState(null);
  const [loading, setLoading] = useState(false); // Added loading state
  const [receiptDate, setReceiptDate] = useState(new Date()); // Added receiptDate state

  const handleFileChange = (e) => setFile(e.target.files[0]);
  const handleStatusChange = (e) => setStatus(e.target.value);
  const handleInfoChange = (e) => setInfo(e.target.value);
  const handleIbanChange = (e) => setIban(e.target.value);
  const handleAmountChange = (e) => setAmount(e.target.value);
  const handleDateChange = (date) => setReceiptDate(date);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("status", status);
    formData.append("info", info);
    formData.append("amount", amount);
    formData.append("receiptDate", receiptDate.toISOString().split('T')[0]);
    if (status === "Rückvergütung offen") {
      formData.append("iban", iban);
    }

    setLoading(true); // Set loading to true before upload
    try {
      await uploadFile(formData, token);
      onFileUploaded();
      setError("");
      setStatus("");
      setInfo("");
      setIban("");
      setAmount(null);
      setSuccess("Yes, das hed klappt!");
      setReceiptDate(new Date());
    } catch (error) {
      console.error("Shit! Etz esch öbbis ned gange! Hesch werkli es File gwählt und es heds en .jpeg oder .pdf Ändig?", error);
      setError(
        "Shit! Etz esch öbbis ned gange! Hesch werkli es File gwählt und es heds en .jpeg oder .pdf Ändig? -> " +
          (error.response ? error.response.data.error : error.message)
      );
      setSuccess("");
    } finally {
      setLoading(false); // Set loading to false after upload
    }
  };

  return (
    <>
      <div className="body">
        <h2>Upload</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="uploadForm.UploadFile">
            <Form.Label>Datei hochladen</Form.Label>
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="uploadForm.statusInput">
            <Form.Label>Detailangaben zum Dokument und zur Zahlung</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={status}
              onChange={handleStatusChange}
            >
              <option value="">Bitte wählen</option>
              <option value="bezahlt mit Eventfactory-Karte">
                bezahlt mit Eventfactory-Karte
              </option>
              <option value="Rückvergütung offen">
                Persönlich bezahlt - Rückvergütung offen
              </option>
              <option value="Rückvergütung abgeschlossen">
                Persönlich bezahlt - Rückvergütung abgeschlossen
              </option>
              <option value="Rechnung offen">
                Rechnung - Bezahlung durch Eventfactory
              </option>
              <option value="Rechnung bezahlt">
              Rechnung - Bezahlung abgeschlossen
              </option>
            </Form.Select>
          </Form.Group>
          {status === "Rückvergütung offen" && (
            <Form.Group className="mb-3" controlId="uploadForm.ibanInput">
              <Form.Label>Deine IBAN</Form.Label>
              <Form.Control
                type="text"
                placeholder="CH00 0000 0000 0000 0000 0"
                onChange={handleIbanChange}
              />
            </Form.Group>
          )}
           <Form.Group className="mb-3" controlId="uploadForm.datePicker">
            <Form.Label>Belegdatum </Form.Label>
            <div>
            <DatePicker selected={receiptDate} onChange={handleDateChange} dateFormat="dd.MM.yyyy" className="form-control" />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="uploadForm.amountInput">
            <Form.Label>Betrag</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>CHF</InputGroup.Text>
              <Form.Control
                value={amount}
                onChange={handleAmountChange}
                aria-label="Amount"
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="uploadForm.remarkInput">
            <Form.Label>Bemerkung</Form.Label>
            <Form.Control
              value={info}
              onChange={handleInfoChange}
              as="textarea"
              rows={2}
            />
          </Form.Group>
          <Form.Group>
            <Button className="mb-3" type="submit" variant="primary" size="lg" disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : "Hochladen"}
            </Button>
          </Form.Group>
          <Form.Group className="mb-3">
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
          </Form.Group>
        </Form>
      </div>
    </>
  );
};

export default FileUpload;
