import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchFileDetails, updateFileDetails } from '../api';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Alert, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";


const FileDetails = ({ token }) => {
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState('');
  const [info, setInfo] = useState('');
  const [iban, setIban] = useState('');
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');
  const [success, setSucess] = useState("");
  const [receiptDate, setReceiptDate] = useState(new Date());
  const navigate = useNavigate();

  useEffect(() => {
    const getFileDetails = async () => {
      try {
        const response = await fetchFileDetails(id, token);
        const fileData = response.data;
        setFile(fileData);
        setStatus(fileData.status);
        setInfo(fileData.info);
        setIban(fileData.iban);
        setAmount(fileData.amount);
        setReceiptDate(new Date(fileData.receipt_date));
      } catch (error) {
        console.error('Oops - Han d Dokumänt ergendwie ned chönne lade... Versuechs bitt spöter nomol.', error);
        setError('Das esch schief gloffe: ' + (error.response ? error.response.data.error : error.message));
        setSucess("");
      }
    };

    getFileDetails();
  }, [id, token]);

  const handleStatusChange = (e) => setStatus(e.target.value);
  const handleInfoChange = (e) => setInfo(e.target.value);
  const handleIbanChange = (e) => setIban(e.target.value);
  const handleAmountChange = (e) => setAmount(e.target.value);
  const handleDateChange = (date) => setReceiptDate(date);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { status, info, iban, amount, receiptDate: receiptDate.toISOString().split('T')[0] };
    setSucess("Top! Han d Änderige gspeicheret.");

    try {
      await updateFileDetails(id, data, token);
      setError('');
    } catch (error) {
      console.error('Error updating file', error);
      setError('Error updating file: ' + (error.response ? error.response.data.error : error.message));
    }
  };

  function handleBack() {
    navigate(`/filelist`);
  }

  if (!file) return <p>Loading...</p>;

  return (
    <>
    <body className="body">
      <h2>{file.name}</h2>
      <br/>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Detailangaben zum Dokument und zur Zahlung</Form.Label>
          <Form.Select
            aria-label="Default select example"
            value={status}
            onChange={handleStatusChange}
          >
            <option value="">Bitte wählen</option>
              <option value="bezahlt mit Eventfactory-Karte">
                bezahlt mit Eventfactory-Karte
              </option>
              <option value="Rückvergütung offen">
                Persönlich bezahlt - Rückvergütung offen
              </option>
              <option value="Rückvergütung abgeschlossen">
                Persönlich bezahlt - Rückvergütung abgeschlossen
              </option>
              <option value="Rechnung offen">
                Rechnung - Bezahlung durch Eventfactory
              </option>
              <option value="Rechnung bezahlt">
              Rechnung - Bezahlung abgeschlossen
              </option>
          </Form.Select>
        </Form.Group>
        <br />
        {status === "Rückvergütung offen" && (
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>IBAN</Form.Label>
            <Form.Control
              value={iban}
              type="text"
              placeholder="CH00 0000 0000 0000 0000 0"
              onChange={handleIbanChange}
            />
          </Form.Group>
        )}
        <Form.Group className="mb-3" controlId="uploadForm.datePicker">
            <Form.Label>Belegdatum </Form.Label>
            <div>
            <DatePicker selected={receiptDate} onChange={handleDateChange} dateFormat="dd.MM.yyyy" className="form-control" />
            </div>
          </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Betrag</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text>CHF</InputGroup.Text>
              <Form.Control
                value={amount}
                onChange={handleAmountChange}
                aria-label="Amount"
              />
            </InputGroup>
          </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Bemerkung</Form.Label>
          <Form.Control
            value={info}
            onChange={handleInfoChange}
            as="textarea"
            rows={2}
          />
        </Form.Group>
        <Button className="mb-3" type="submit" variant="primary" size="lg">
          Anpassen
        </Button>{" "}
        <Button className="mb-3" onClick={handleBack} variant="outline-secondary" size="lg">
          Zurück
        </Button>{" "}
        <br/>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
      </Form>
    </body>
    </>
  );
};

export default FileDetails;
