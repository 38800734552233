import React, { useState } from 'react';
import { loginUser } from '../api';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate('/register'); // Navigiere zur Registrierungsseite
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser({ username, password });
      onLogin(response.data.access_token);
    } catch (error) {
      setError('Ungültige Username oder s falsche Passwort... Versuechs nomol');
    }
  };

  return (
    <body className="body">
    <Form onSubmit={handleSubmit}>
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
      <Form.Label>E-Mail Adresse</Form.Label>
      <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="name@example.com" />
    </Form.Group>
    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
      <Form.Label>Passwort:</Form.Label>
      <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </Form.Group>
    <Button type="submit" variant="success">Login</Button>{' '}
    <Button onClick={handleRegisterClick} variant="outline-primary">Registrieren</Button>{' '}
  </Form>
  </body>
  );
};

export default Login;
