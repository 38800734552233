import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchFiles, downloadFile } from "../api";
import { Button, Alert, Image, Row, Badge, ListGroup, Spinner } from "react-bootstrap";


const FileList = ({ onSelectFile, shouldUpdate, token }) => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getFiles = async () => {
      try {
        const response = await fetchFiles(token);
        setFiles(response.data);
        setError("");
      } catch (error) {
        console.error("Error fetching files", error);
        setError(
          "Error fetching files: " +
            (error.response ? error.response.data.error : error.message)
        );
      }
    };

    getFiles();
  }, [shouldUpdate, token]);

  const handleFileClick = (fileId) => {
    onSelectFile(fileId);
    navigate(`/filedetails/${fileId}`);
  };

  const handleDownload = async (fileId, filename) => {
    setLoading(true);
    try {
      const response = await downloadFile(fileId, token);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Hoppla, han s File ned chönne abelade... Versuechs spöter nomol ", error);
      setError(
        "Hoppla, han s File ned chönne abelade... Versuechs spöter nomol " +
          (error.response ? error.response.data.error : error.message)
      );
    }
    setLoading(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(date);
  };

  return (
    <body className="body">
      <h2>Deine Dokumente</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {files.length === 0 && <Alert variant="primary"><Row className="justify-content-md-center" ><Image className="mb-3" src="./nothing_to_see.jpeg" rounded center /><div className="mb-3 file-info">Hmm... No ned vell z gsee do! Lad es File ufe, denn gsesch do dini Dateie</div></Row></Alert>}
      <ListGroup as="ol">
        {files.map((file) => (
          <ListGroup.Item
            key={file.id}
            className="d-flex justify-content-between align-items-start"
            variant="light"
          >
            <div
              onClick={() => handleFileClick(file.id)}
              as="li"
              className="ms-2 me-auto"
              to={`/filedetails/${file.id}`}
            >
              <div className="fw-bold file-info">{file.info}</div>
              <div>
                Betrag: CHF {file.amount} | Belegdatum: {formatDate(file.receipt_date)}
              </div>
              <div>
                {(file.status === "Rückvergütung offen") || (file.status === "Rechnung offen") ? (
                  <Badge bg="danger">{file.status}</Badge>
                ) : (
                  <Badge bg="success">{file.status}</Badge>
                )}
              </div>
            </div>
            <Button
              onClick={() => handleDownload(file.id, file.name)}
              size="sm" variant="outline-secondary"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Download"}
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </body>
  );
};

export default FileList;
