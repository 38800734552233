import './index.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import FileUpload from './components/FileUpload';
import FileList from './components/FileList';
import FileDetails from './components/FileDetails';
import Login from './components/Login';
import Register from './components/Register';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { jwtDecode } from 'jwt-decode';

const App = () => {
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [isTokenValid, setIsTokenValid] = useState(true);

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          handleLogout();
        }
      }
    };

    checkTokenExpiration();
    const interval = setInterval(checkTokenExpiration, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [token]);

  const handleFileUploaded = () => {
    setSelectedFileId(null); // Reset selection after upload
    setShouldUpdate(prev => !prev); // Trigger update of FileList
  };

  const handleLogin = (token) => {
    setToken(token);
    localStorage.setItem('token', token);
  };

  const handleLogout = () => {
    setToken('');
    localStorage.removeItem('token');
    setIsTokenValid(false);
  };

  useEffect(() => {
    if (!token) {
      setIsTokenValid(false);
    } else {
      setIsTokenValid(true);
    }
  }, [token]);

  return (
    <Router>
      <div>
        <header className="header">
          {isTokenValid ? (
            <Navbar expand="lg" className="bg-body-tertiary">
              <Container>
                <Navbar.Brand as={Link} to="/">Eventfactory - Belegverwaltung</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link as={Link} to="/">Upload</Nav.Link>
                    <Nav.Link as={Link} to="/filelist">Deine Dokumente</Nav.Link>
                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          ) : (
            <Navbar.Brand as={Link} to="/">Eventfactory - Belegverwaltung</Navbar.Brand>
          )}
        </header>
        <Routes>
          {isTokenValid ? (
            <>
              <Route path="/" element={<FileUpload onFileUploaded={handleFileUploaded} token={token} />} />
              <Route path="/filelist" element={<FileList onSelectFile={setSelectedFileId} shouldUpdate={shouldUpdate} token={token} />} />
              {selectedFileId && <Route path="/filedetails/:id" element={<FileDetails fileId={selectedFileId} token={token} />} />}
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route path="/login" element={<Login onLogin={handleLogin} />} />
              <Route path="/register" element={<Register />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
