import React, { useState } from 'react';
import { registerUser } from '../api';
import { Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleFirstLogin = () => {
    navigate('/login'); // Navigiere zur Registrierungsseite
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate email format
    const emailPattern = /^[a-zA-Z0-9._%+-]+@sff-malters\.ch$/;
    if (!emailPattern.test(username)) {
      setError('Bitte gib dini SFF E-Mail ii! Anderi E-Mail Adrässene sind ned gültig!');
      setMessage('');
      return;
    }
    
    try {
      await registerUser({ username, password });
      setMessage('Top! Ha di chönne registriere, vell Spass :)');
      setError('');
    } catch (error) {
      setError('Sorry, ha di ned chönne registriere. Hesch ächt scho en Account?');
      setMessage('');
    }
  };

  return (
    <div className="body">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>E-Mail Adresse</Form.Label>
          <Form.Control 
            type="text" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            placeholder="name@email.com" 
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Passwort:</Form.Label>
          <Form.Control 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
          />
        </Form.Group>
        <Button className="mb-3" type="submit" variant="success">Registrieren</Button>
        {message && (
          <div>
            <Alert variant="success">{message}</Alert>
            <Button onClick={handleFirstLogin} className="mb-3" variant="success">
              Weiter zum Login
            </Button>
          </div>
        )}
        {error && (
          <Alert variant="danger">{error}</Alert>
        )}
      </Form>
    </div>
  );
};

export default Register;
