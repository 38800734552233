import axios from 'axios';

const api = axios.create({
  baseURL: 'https://kartei.co.uk/api',
});

export const logoutUser = () => {
  localStorage.removeItem('token');
};

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      logoutUser();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const uploadFile = (formData, token) => api.post('/upload', formData, {
  headers: { Authorization: `Bearer ${token}` },
});
export const fetchFiles = (token) => api.get('/files', {
  headers: { Authorization: `Bearer ${token}` },
});
export const fetchFileDetails = (id, token) => api.get(`/file/${id}`, {
  headers: { Authorization: `Bearer ${token}` },
});
export const updateFileDetails = (id, data, token) => api.put(`/file/${id}`, data, {
  headers: { Authorization: `Bearer ${token}` },
});
export const downloadFile = (id, token) => {
  return api.get(`/file/download/${id}`, {
    responseType: 'blob', // Wichtig für den Download von Dateien
    headers: { Authorization: `Bearer ${token}` }
  });
};

const authApi = axios.create({
  baseURL: 'https://kartei.co.uk/auth',
});

export const registerUser = (data) => authApi.post('/register', data);
export const loginUser = (data) => authApi.post('/login', data);
